// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import invoices from './invoices'
import lookups from './lookups'
import machines from './machines'


const rootReducer = {
  navbar,
  layout,
  invoices,
  lookups,
  machines
}

export default rootReducer
