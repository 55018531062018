import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';

// import api from "axios";
export const fetchAll = createAsyncThunk(
    'machines/fetchAll',
    async (params) => {
        const response = await api.post("/api/machines/search", params);
        return response.data;
    }
);


export const create = createAsyncThunk(
    'machines/create',
    async (payload) => {
        const response = await api.post(`/api/machines`, payload)
        return response.data;
    }
);
export const register = createAsyncThunk(
    'machines/register',
    async (payload) => {
        const response = await api.post(`/api/machines/register`, payload)
        return payload;
    }
);
export const generateToken = createAsyncThunk(
    'machines/generateToken',
    async (payload) => {
        const response = await api.post(`/api/machines/token`, payload)
        return response;
    }
);

const machinesSlice = createSlice({
    name: 'machines',
    initialState: {
        items: [],
        loading: false,
        selectedItem: {},
        formOpened: false,
        otpOpened: false,
    },
    reducers: {
        // Other reducer actions...
        setFormOpened: (state, { payload }) => {
            state.formOpened = payload;
        },
        setOtpOpened: (state, { payload }) => {
            state.otpOpened = payload;
        },
        setItem: (state, { payload }) => {
            console.log("payload", payload);
            state.selectedItem = payload;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
            .addCase(create.fulfilled, (state, { payload: { machine } }) => {
                state.items.push(machine)
                state.formOpened = false;
            })
            .addCase(register.fulfilled, (state, action) => {
                var index = state.items.findIndex(item => item.id === action.payload.id)
                state.items[index] = { ...state.items[index], isProduction: true };
                state.otpOpened = false;
            })

    },
});


export const { setFormOpened, setItem, setOtpOpened } = machinesSlice.actions
export default machinesSlice.reducer;

