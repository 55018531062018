import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';
import toast from 'react-hot-toast';

// import api from "axios";
export const fetchAll = createAsyncThunk(
    'invoices/fetchAll',
    async (params) => {
        //const response = await api.get("/api/invoice/list-invoice", { params })
        const response = await api.post("/api/invoice/search", params);
        return response.data;
    }
);

export const fetchById = createAsyncThunk(
    'invoices/fetchById',
    async (id) => {
        const response = await api.get(`/api/invoice/${id}`)
        return response.data;
    }
);
export const fetchFaildHistoryId = createAsyncThunk(
    'invoices/fetchFaildHistoryId',
    async ({ id, successCB }, { dispatch }) => {
        const response = await api.get(`/api/invoice/faild-history/${id}`)
        successCB && successCB(dispatch)
        return response.data;

    }
);
export const create = createAsyncThunk(
    'invoices/create',
    async (payload) => {
        const response = await api.post(`/api/invoice`, payload)
        return response.data;
    }
);
export const exportToExcel = createAsyncThunk(
    'invoices/exportToExcel',
    async (payload) => {
        const response = await api.post(`/api/invoice/export-to-excel`, payload)
        return;
    }
);
export const getDashBoardSummaries = createAsyncThunk(
    'invoices/getDashBoardSummaries',
    async (payload) => {
        const response = await api.post(`/api/invoice/dashboard-summaries`, payload)
        return response.data;
    }
);

const invoicesSlice = createSlice({
    name: 'invoices',
    initialState: {
        items: [],
        loading: false,
        excelOpened: false,
        error: null,
        invoicesStatusSummary: [],
        invoicesTypeSummary: [],
        invoicesSummary: [],
        last7DaysInvoices: [],
        last7DaysTax: [],
        file: "",
        faildHistory: {
            validationResults: {
                infoMessages: [],
                warningMessages: [],
                errorMessages: [],
                status: ""
            },
            reportingStatus: ""
        },
        errorModalOpened: false,
    },
    reducers: {
        // Other reducer actions...
        toggleErrorModal: (state, { payload }) => {
            state.errorModalOpened = payload;
        },
        toggleExcel: (state, { payload }) => {
            state.excelOpened = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
            .addCase(fetchById.fulfilled, (state, action) => {
                state.selectedInvoice = action.payload;
                console.log("action.payload", action.payload);
                // const { successCallBack } = action.meta.arg;
                // successCallBack && successCallBack(action.payload)
            })
            .addCase(fetchFaildHistoryId.fulfilled, (state, action) => {
                state.faildHistory = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(create.fulfilled, (state, action) => {
                toast.success('Request Executed Successfully')
            })
            .addCase(exportToExcel.fulfilled, (state, action) => {
                toast.success('Request Executed Successfully, you will receive an email with the excel file')
                state.excelOpened = false;
            })
            .addCase(getDashBoardSummaries.fulfilled, (state, action) => {
                console.log("action.payload", action);
                state.invoicesStatusSummary = action.payload.invoicesStatusSummary;
                state.invoicesTypeSummary = action.payload.invoicesTypeSummary;
                state.invoicesSummary = action.payload.invoicesSummary;
                state.last7DaysTax = action.payload.last7DaysTax;
                state.last7DaysInvoices = action.payload.last7DaysInvoices;
            })
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.loading = true;
                    state.error = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => {
                    state.loading = false;
                    state.error = null;
                    const { type } = action
                    // console.log("type",type)
                    // ['create','update','delete','edit','remove','add'].some(v => type.includes(v)) &&
                    // toast.success('Request Executed Successfully')
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.loading = false;
                    state.error = action.error.message;
                    toast.error('There is an error occured !')
                }
            )
    },
});


export const { toggleErrorModal, toggleExcel } = invoicesSlice.actions
export default invoicesSlice.reducer;

